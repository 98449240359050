import React, { useRef } from 'react'
import { Paper } from '@material-ui/core';
import MaterialTable from 'material-table';
import { greyBackgroundColor } from '../../themes/GlobalTheme';
import { useSelector } from 'react-redux';
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import * as cst from '../../js/constants/miscellaneous_cst';
import { fetchData } from '../../js/utils/backend';
import { Thumbnail, DateTimeHourRenderer, CurrencyRenderer } from '../Widget/TableEditors';


const localStyle = makeStylesGlobal((theme) => ({
}));



function Bids({ history }) {
    const token = useSelector(state => state.auth.token);
    const tableRef = useRef();
    const classes = localStyle();

    return (
        <>
            <MaterialTable
                components={{
                    Container: props => <Paper {...props} className={classes.NVisiblePaper}/>
                }}
                style={{ backgroundColor: greyBackgroundColor}}
                tableRef={tableRef}
                columns={[
                    {
                        title: "Image",
                        field: "ImageID",
                        cellStyle: {width: '10%', padding: 0 },
                        render: rowData => Thumbnail(70, rowData.ImageID),
                        sorting: false,
                    },
                    {
                        title: "My Current Bid",
                        field: "Value",
                        sorting: false,
                        headerStyle: { textAlign: "right"},
                        cellStyle: { textAlign: "right"},
                        render: rowData => <>{CurrencyRenderer( rowData.Value, true)}</>
                    },
                    {
                        title: "My Maximum Bid",
                        field: "MaxValue",
                        headerStyle: { textAlign: "right"},
                        cellStyle: { textAlign: "right"}, 
                        sorting: false,
                        render: rowData => <>{CurrencyRenderer( rowData.MaxValue, true)}</>
                    },
                    {
                        title: "Auction",
                        field: "AuctionTitle",
                        cellStyle: { textAlign: "left" },
                        sorting: false,
                    },
                    {
                        title: "Lot",
                        field: "AuctionNumber",
                        headerStyle: { textAlign: "right" },
                        cellStyle: { textAlign: "right"},
                        sorting: false,

                    }, 
                    {
                        title: "Product",
                        field: "Title",
                        sorting: false,
                        cellStyle: {width: '25%', padding: 0 },

                    },
                    {
                        title: "Time",
                        field: "Time",
                        cellStyle: { width: '200px'},
                        render: rowData => DateTimeHourRenderer(200, rowData.Time),
                        sorting: true,
                        defaultSort: "desc"
                    }

                ]}
                data={query => {
                    return fetchData(token, query, cst.myBids)
                }}
                onRowClick={(event, rowData) => { history.push(rowData.URL) }}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 5,
                    pageSizeOptions: [],
                    draggable: false,
                    showTextRowsSelected: false,
                    search: false,
                    sorting: true,
                    selectionProps: rowData => ({
                        color: 'primary'
                    }),
                    headerStyle: { textAlign: "left", backgroundColor: greyBackgroundColor },
                    cellStyle: { textAlign: "left" },
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: 'No bids to display'
                    },
                }}
            
                title={"Bids"}
            />
        </>
    )
}

export default Bids
