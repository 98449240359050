import React from 'react'
import { Button } from '@material-ui/core';
import {getInvoiceData, getSettlementData, getObjectsListingData, getPhoneRequests, getObjectData} from "../Documents/fetch" 
import InvoicePDF from "../Documents/PdfTemplates/InvoicePDF" 
import SettlementPDF from "../Documents/PdfTemplates/SettlementPDF" 
import ObjectsListingPDF from "../Documents/PdfTemplates/ObjectsListingPDF"
import ObjectsResultPDF from "../Documents/PdfTemplates/ObjectsResultPDF"
import PhoneRequestPDF from "../Documents/PdfTemplates/PhoneRequestPDF"
import ObjectPDF from "../Documents/PdfTemplates/ObjectPDF"
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { pdf } from '@react-pdf/renderer';

import IconButton from '@material-ui/core/IconButton';

const localStyle = makeStylesGlobal(theme => ({

    StoreButton: {
        color: theme.ColorC1,
        margin: "5px 0px",
    },
}));

// 0
export async function downloadPdf(documentType, documentID, token) {
    let data = null;
    switch (documentType) {
        case "invoice":
            data = await getInvoiceData(documentID);
            await engageDownload(data, "Native-" + data.Number + ".pdf", documentType);
            break;
        case "settlement":
            data = await getSettlementData(documentID);
            await engageDownload(data, "Native-" + data.Number + ".pdf", documentType);
            break;
        case "group": 
            data = await getObjectsListingData(documentID, token, false);
            await engageDownload(data, "Native-" + data.Title + ".pdf", documentType);
            break;
        case "results": 
            data = await getObjectsListingData(documentID, token, false);
            await engageDownload(data, "Native-" + data.Title + "-Results.pdf", documentType);
            break;
        case "resultsNoPremium":
            data = await getObjectsListingData(documentID, token, true);
            await engageDownload(data, "Native-" + data.Title + "-Results.pdf", "results");
            break;
        case "phonerequest":
            data = await getPhoneRequests(documentID, token);
            await engageDownload(data, "PhoneRequests.pdf", documentType);
            break;
        case "single":
            data = await getObjectData(documentID, token);
            await engageDownload(data, "Native-" + data.Title + ".pdf", documentType);
            break;
        default:
            console.error("component not handled, type:" + documentType)
    }
}

// 1
const engageDownload = async (docData, title, documentType) => {
    console.log(docData)
    let blob = await generatePdfDocument(docData, documentType);
    var encodedUri = window.URL.createObjectURL(blob)
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", title);
    document.body.appendChild(link);
    link.click()
    document.body.removeChild(link);
}

// 2 
const generatePdfDocument = async (docData, documentType) => {
    return await pdf((
        <PDF docData={docData} documentType={documentType} />
    )).toBlob()
};

// 3
const PDF = ({ docData, documentType }) => {
    switch (documentType) {
        case "invoice":
            return <InvoicePDF billData={docData} />
        case "settlement":
            return <SettlementPDF billData={docData} />
        case "group": 
            return <ObjectsListingPDF data={docData} />
        case "results": 
            return <ObjectsResultPDF premium="true" data={docData} /> 
        case "resultsNoPremium": 
            return <ObjectsResultPDF premium="false" data={docData} /> 
        case "phonerequest":
            return <PhoneRequestPDF data={docData} />
        case "single":
            return <ObjectPDF data={docData} />
        default:
            return null
    }
}

export const DownloadIcon = ({ documentType, documentID, children, ...props }) => {
    const classes = localStyle();


    return (
        <IconButton className={classes.Icon} onClick={() => downloadPdf(documentType, documentID)} {...props}>{children}</IconButton>
    )
}

export const DownloadButton = ({ documentType, documentID, children, ...props }) => {
    
    const classes = localStyle();

    return (
        <Button className={classes.StoreButton} onClick={() => downloadPdf(documentType, documentID)} {...props} > {children} </Button>
    )
}
