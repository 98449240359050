import React from 'react'
import { Page, Document, StyleSheet, View, Text, Image } from '@react-pdf/renderer'
import Title from "../ContentLayout/Common/Title";
import { tableColumnStyle, htmlToStringDetail } from '../pdf_cst';
import ContactDetails from '../ContentLayout/Common/ContactDetails';
import { listing } from '../../../js/constants/miscellaneous_cst';

const styles = StyleSheet.create(tableColumnStyle);

export default function ObjectsListingPDF ({data}) {
    let type = listing

    const styles = StyleSheet.create({...tableColumnStyle,
        row: {
            flexDirection: 'row',
            marginBottom: 32
        },
        titleContact : {
            flexDirection: 'column'

        },
        email : {
            textAlign: "center"
        },
        objectTitle: {
            fontSize: 16,
            marginBottom: 12
        },
        objectSubtitle: {
            fontSize: 12,
            marginBottom: 9
        },
        bigImage: {
            width: '100%', 
        },
        imagesContainer: {
            flexDirection: 'row',
            flexWrap: 'wrap'
        },
        imageContainer: {
            width: '33.3%',
            paddingRight: 8,
            paddingTop: 4,
            paddingBottom: 4
        }
    })

    return (
        <Document title={"Native-" + data.Title + ".pdf"}>
            <Page 
                size="A4" // {[595.28, 841.89]} 
                style={styles.page}
            >
                <View>
                    <Title title={"Native"}/>
                    <Text style={styles.objectTitle}>
                        {data.Title}
                    </Text>
                    <Text style={styles.objectSubtitle}>
                        {data.SubTitle}
                    </Text>
                    <Text>
                        {htmlToStringDetail(data.Description)}
                    </Text>
                    <View style={styles.imagesContainer}>
                        {data.OfficialImagesID && data.OfficialImagesID[0] && data.OfficialImagesID.slice(0, 3).map((image, index) => (
                            <View key={index} style={styles.imageContainer}>
                        <Image
                            style={styles.bigImage}
                            wrap={false}
                            key={index}
                            src={`/Image/${image}`}
                        />
                                </View>
                    ))}
                    </View>
                </View>
                <Text
                    fixed
                    style={styles.BottomEmail}
                >
                    www.native-auctions.com
                </Text>
                <Text 
                    fixed
                    style={styles.PageNumber} 
                    render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} 
                />
            </Page>
        </Document>
    )
}